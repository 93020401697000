<template>
  <div class="the-page">
    <component
      :is="customPageComponent"
      v-if="customPageComponent"
    />

    <template v-else>
      <component
        :is="pageBlockComponent"
        v-for="(block, index) of blocks"
        :key="`${pageConfig.url}${block}`"
        :pageUrl="pageConfig.url"
        :entityType="block"
        :showSidebarButton="index === 0"
        @entityClick="editEntity"
      />
    </template>
  </div>
</template>

<script>
import store from '@/store';
import PageBlock from './pageblock/PageBlock.vue';
import Log from './log/TheLog';
import MetaForm from '../meta-form/MetaForm';
import PageBlockPublishable from './pageblock/PageBlock_Publishable.vue';

export default {
  name: 'ThePage',

  components: {
    PageBlock,
    PageBlockPublishable,

    // используются как значения свойства customprops.customPage в конфиге страницы:
    Log,
    MetaForm,
  },

  computed: {
    pageConfig() {
      return store.state.activeSidebarItem || {};
    },
    blocks() {
      return this.pageConfig.code ? [this.pageConfig.code] : [];
    },
    customPageComponent() {
      return this.pageConfig?.customprops?.customPage;
    },
    isPublishableEntity() {
      if (!this.pageConfig.code) return false;
      return !!store.state.meta.components[this.pageConfig.code]?.publishable;
    },
    pageBlockComponent() {
      return this.isPublishableEntity ? PageBlockPublishable : PageBlock;
    },
  },

  methods: {
    editEntity(...args) {
      this.$emit('editEntity', ...args);
    },
  },
};
</script>

<style lang="scss">
.the-page {
  padding-bottom: 40px;
  padding-left: 10px;
  padding-right: 10px;
  overflow-x: visible;
}

@media (min-width: $desktopBreakpoint) {
  .the-page {
    @include scrollbars();
    height: 100%;
    overflow-y: scroll;

    &.noscroll {
      overflow-y: hidden;
    }
  }

  .menu--vertical .the-page {
    padding-left: 40px;
    padding-right: 26px;

    &.noscroll {
      padding-right: 34px;
    }
  }

  .menu--horizontal .the-page {
    padding-left: 24px;
    padding-right: 24px;

    &.noscroll {
      padding-right: 32px;
    }
  }
}
</style>
